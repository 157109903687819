<template>
    <b-overlay :show="showLoading" spinner-variant="primary">
        <b-card>
            <b-form class="mt-2">
                <b-row>
                    <b-col sm="4">
                        <b-form-group label="Entity Name">
                            <b-form-input v-model="vendor.entityName" placeholder="Nom" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Email">
                            <b-form-input v-model="vendor.email" placeholder="Email" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Type">
                            <v-select v-model="vendor.type" label="title" :options="types" placeholder="Type" />
                        </b-form-group>
                    </b-col>

                    <b-col sm="4">
                        <b-form-group label="Phone">
                            <b-form-input v-model="vendor.phone" placeholder="Phone" />
                        </b-form-group>
                    </b-col>
                    <b-col sm="4">
                        <b-form-group label="Postal code">
                            <b-form-input v-model="vendor.postalCode" placeholder="Code postal" />
                        </b-form-group>
                    </b-col>

                    <b-col sm="12">
                        <b-form-group label="Address">
                            <b-form-input v-model="vendor.address" placeholder="Address" />
                        </b-form-group>
                    </b-col>

                    <b-col cols="12" class="text-right">
                        <!-- <b-button variant="success" class="mt-4" @click="createVendor()">
                            Sauvegarder
                        </b-button> -->
                        <b-button @click="createVendor()" class="mt-4" variant="relief-success">
                            <feather-icon icon="PocketIcon" class="mr-50" />
                            <span class="align-middle">Save</span>
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-card>
    </b-overlay>
</template>
  
<script>

import vSelect from 'vue-select'

export default {
    components: {
        vSelect
    },
    data() {
        return {
            showLoading: false,
            vendor: {
                entityName: '',
                address: '',
                phone: '',
                email: '',
                postalCode: '',
                type: '',
            },
            types: [{ title: 'PARA' }, { title: 'MED' }],
        }
    },
    watch: {
        'pharmacy.governorate'(val) {
            this.vendor.delegation = "";
            this.delegations = this.allDelegations.filter(item => item.govId === val.id);
        }
    },
    created() {
    },
    methods: {
        async createVendor() {
            console.log(this.vendor);
            this.showLoading = true;
            let body = {
                entityName: this.vendor.entityName,
                address: this.vendor.address,
                phone: this.vendor.phone,
                type: this.vendor.type.title,
                email: this.vendor.email,
                postalCode: this.vendor.postalCode
            };
            console.log(this.vendor);
            console.log(body);
            let resp = await this.$http.post("_vendor/add", body).catch(() => this.showLoading = false);
            if (resp.status == 200)
                this.$router.push({ name: 'supervisor-vendor' });
        }
    },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>